(function (window, document) {
  function createHttpRequest() {
    if (window.ActiveXObject) {
      return new ActiveXObject("Microsoft.XMLHTTP");
    }
    else if (window.XMLHttpRequest) {
      return new XMLHttpRequest();
    }
  }
  function AliLogTracker(host, project, logstore) {
    this.uri_ = window.location.protocol + '//' + project + '.' + host + '/logstores/' + logstore + '/track?APIVersion=0.6.0';
    this.params_ = new Array();
    this.httpRequest_ = createHttpRequest();
  }
  AliLogTracker.prototype = {
    push: function (key, value) {
      if (!key || !value) {
        return;
      }
      this.params_.push(key);
      this.params_.push(value);
    },
    logger: function () {
      var url = this.uri_;
      var k = 0;
      while (this.params_.length > 0) {
        if (k % 2 == 0) {
          url += '&' + encodeURIComponent(this.params_.shift());
        }
        else {
          url += '=' + encodeURIComponent(this.params_.shift());
        }
        ++k;
      }
      try {
        this.httpRequest_.open("GET", url, true);
        this.httpRequest_.send(null);
      }
      catch (ex) {
        if (window && window.console && typeof window.console.log === 'function') {
          console.log("Failed to log to ali log service because of this exception:\n" + ex);
          console.log("Failed log data:", url);
        }
      }
    }
  };
  window.Tracker = AliLogTracker;
})(window, document);

const platform = require('platform');
const logger = new window.Tracker('cn-hangzhou.log.aliyuncs.com', 'imm-f2e-log', 'doc-preview-f2e');

// 捕获promise异常错误
window.addEventListener("unhandledrejection", function (e) {
  const reason = e.reason || {};

  pushData({
    type: 'UnhandledRejection',
    data: reason.message,
    url: window.location.href,
    description: reason.stack
  });
});

// 捕获js错误
window.addEventListener('error', function (err) {
  pushData({
    type: 'Error',
    data: err.message,
    url: window.location.href,
    src: err.filename,
    description: `line: ${err.lineno}, colno: ${err.colno}`
  });
});

// 打点性能信息以及设备信息
window.addEventListener('load', function () {
  deviceTrack();
  // 连续2次请求频率可能会导致数据第一次请求被cancel，这里延迟1s再执行
  setTimeout(performanceTrack, 1000);
});

function performanceTrack() {
  // 性能数据统计
  const performance = window.performance;
  if (performance && performance.timing) {
    const t = performance.timing;
    const loadPageTime = t.domComplete - t.navigationStart;

    pushData({
      type: 'Performance',
      loadTime: loadPageTime,
      ua: window.navigator.userAgent,
      url: window.location.href,
      description: JSON.stringify({
        lookupDomain: t.domainLookupEnd - t.domainLookupStart,
        request: t.responseEnd - t.requestStart,
        ttfb: t.responseStart - t.navigationStart,
        domReady: t.domComplete - t.responseEnd
      })
    });
  }
};

function deviceTrack() {
  // 获取设备信息
  pushData({
    type: 'Device',
    data: platform.name || 'unknown',
    url: window.location.href,
    src: platform.version,
    description: platform.description,
    ua: window.navigator.userAgent
  });
};

function isObject(arg) {
  return Object.prototype.toString.call(arg) === '[object Object]';
};

function pushData(json) {
  if (!isObject(json)) {
    return;
  }

  Object.keys(json).forEach(key => {
    logger.push(key, json[key]);
  });

  logger.logger();
};